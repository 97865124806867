<template>
    <div class="aboutUsWrap">
        <Header></Header>
        <topBg>
            <div slot="text" class="textWrap" v-html="$t('oa.contcontactusTips')"></div>
        </topBg>
        <div class="formContent">
            <el-form ref="contactUs" :model="contactUs" :rules="contactUsRules" class="contactUs">
                <el-form-item prop="name">
                    <el-input v-model="contactUs.name" :placeholder="$t('oa.yourName')"></el-input>
                </el-form-item>
                <el-form-item prop="email">
                    <el-input v-model="contactUs.email" :placeholder="$t('oa.emailCus')"></el-input>
                </el-form-item>
                <el-form-item prop="iphone">
                    <el-input v-model="contactUs.iphone" :placeholder="$t('oa.phone')"></el-input>
                </el-form-item>
                <el-form-item prop="subject">
                    <el-input v-model="contactUs.subject" :placeholder="$t('oa.subject')"></el-input>
                </el-form-item>
                <el-form-item prop="content">
                    <el-input v-model="contactUs.content" type="textarea" :placeholder="$t('oa.message')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="submitButton submitBtn" @click="submitForm('contactUs')" round :disabled="loadingSubmit" :loading="loadingSubmit">{{$t('oa.submit')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import url from "@/api/url.js";
import Header from "@/components/header.vue";
import topBg from "@/components/topBg.vue";
export default {
    components: {
        Header,
        topBg,
    },
    data() {
        return {
            loadingSubmit: false,
            contactUs: {
                name: "",
                email: "",
                iphone: "",
                subject: "",
                content: "",
            },
            contactUsRules: {
                name: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                ],
                email: [
                    { required: true, message: "请输入邮箱", trigger: "blur" },
                ],
                iphone: [
                    { required: true, message: "请输入手机", trigger: "blur" },
                ],
                subject: [
                    { required: true, message: "请输入主题", trigger: "blur" },
                ],
                content: [
                    { required: true, message: "请输入信息", trigger: "blur" },
                ],
            },
        };
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.initLang();
        },
    },
    created() {
        this.initLang();
    },
    methods: {
        initLang() {
            this.contactUsRules.name[0].message = this.$t("oa.enterYourName");
            this.contactUsRules.email[0].message = this.$t("oa.enteremail");
            this.contactUsRules.iphone[0].message = this.$t("oa.enterPhone");
            this.contactUsRules.subject[0].message =
                this.$t("oa.eneterSubject");
            this.contactUsRules.content[0].message =
                this.$t("oa.eneterMessage");
        },
        submitForm(formName) {
            if (this.loadingSubmit) return;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLogin(formName);
                } else {
                    return false;
                }
            });
        },
        submitLogin(formName) {
            this.loadingSubmit = true;
            this.$axios
                .post(url.contactUs, {
                    ...this.contactUs,
                })
                .then((res) => {
                    this.$message({
                        type: "success",
                        message: this.$t("oa.emailSuccess"),
                    });
                })
                .finally(() => {
                    this.loadingSubmit = false;
                    this.resetForm(formName);
                });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>
<style lang="scss" scoped>
.aboutUsWrap {
    background-color: #fff;
    .formContent {
        width: 1320px;
        min-height: 500px;
        margin: 0 auto;
        padding: 80px 0;
        .contactUs {
            width: 650px;
            margin: 0 auto;
            /deep/.el-input__inner,
            /deep/.el-textarea__inner {
                height: 60px;
                background-color: transparent;
                // border: 2px solid #eee;
                border-radius: 5px;
                color: #000;
                font-family: "FrutigerLTStd";
                &::placeholder {
                    color: #bfc5d1;
                    font-size: 14px;
                    font-family: "FrutigerLTStd";
                }
            }
            /deep/.el-textarea__inner {
                height: 200px;
                font-family: "微软雅黑";
            }
            /deep/.el-input__inner:focus,
            /deep/.el-textarea__inner:focus {
                border-color: #eee;
            }
            /deep/.el-form-item__content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                span {
                    font-size: 24px !important;
                }
                .el-icon-loading {
                    font-size: 24px !important;
                }
            }
            .submitButton {
                width: 100%;
                display: block;
                border-radius: 30px;
                background-color: #305a9e;
                border-color: #305a9e;
                color: #fff;
            }
        }
    }
}
</style>